<template>
  <div class="dashboard-topbar">
    <div class="dashboard-filters" v-if="isOnline">
      <div class="dashboard-filter" style="max-width: 15rem;">
        <SelectExtended v-if="yearsData.length" v-model="filterYearByAgriculturalSeason" label="Année" optionKey="id"
          optionValue="valeur" multiple :search="false" :items="yearsData" @update:modelValue="emitParams()" />
      </div>
      <div class="dashboard-filter">
        <SelectExtended v-model="filterThematique" label="Thématique" :apiParams="{ sort: 'designation.ASC', limit: 0 }"
          optionKey="id" optionValue="designation" apiEndpoint="dictionnaire/thematique" multiple :search="false"
          @update:modelValue="emitParams()" />
      </div>
      <div class="dashboard-filter">
        <SelectExtended v-model="filterCulture" label="Culture" optionKey="id" optionValue="nom" apiEndpoint="culture"
         :apiParams="{ limit: 0, sort: 'nom.ASC' }"
          multiple :search="true" @update:modelValue="emitParams()" />
      </div>
      <template v-if="isUnion">
        <div v-if="entity_id" class="dashboard-filter">
        <SelectExtended v-model="filterEntite" label="Entité" optionKey="id" optionValue="nom" apiEndpoint="entite" :apiParams="{ sort: 'nom.ASC', limit:0, filters: `parent:eq(${entity_id})`}"
          multiple :search="false"  @update:modelValue="emitParams()" />
        </div>
        <div class="dashboard-filter">
          <SelectExtended v-model="filterProtocol" label="Protocole" optionKey="id" optionValue="titre" apiEndpoint="protocole" :apiParams="{ sort: 'titre.ASC', limit:0, filters: protocolFilters }"
            multiple :search="false" @update:modelValue="emitParams()" />
        </div>
      </template>

    </div>
    <div class="dashboard-tools" v-if="isOnline">
      <div class="dashboard-tool">
        <SelectExtended v-if="menuWidgets.length > 0" v-model="displayWidgets" label="Affichage" option-key="value"
          option-value="label" :items="menuWidgets" multiple />
      </div>
    </div>
    <div class="dashboard-infos">
      <div class="dashboard-info" v-if="filterYearByAgriculturalSeason.length">
        <div class="dashboard-info-label">
          <span>Année&nbsp;:</span>
        </div>
        <div class="dashboard-info-value dashboard-info-value--tags">
          <MiniTag v-for="(year, index) in filterYearByAgriculturalSeason" :key="index" :text="year.value" color="material-color-10"/>
        </div>
      </div>
      <div class="dashboard-info" v-if="filterThematique.length">
        <div class="dashboard-info-label">
          <span>Thématique&nbsp;:</span>
        </div>
        <div class="dashboard-info-value">
          <span>{{ filterThematique.map(f => f.value).join(', ')  }}</span>
        </div>
      </div>
      <div class="dashboard-info" v-if="filterCulture.length">
        <div class="dashboard-info-label">
          <span>Culture&nbsp;:</span>
        </div>
        <div class="dashboard-info-value">
          <span>{{ filterCulture.map(f => f.value).join(', ')  }}</span>
        </div>
      </div>
      <div class="dashboard-info" v-if="filterEntite.length">
        <div class="dashboard-info-label">
          <span>Entité&nbsp;:</span>
        </div>
        <div class="dashboard-info-value">
          <span>{{ filterEntite.map(f => f.value).join(', ')  }}</span>
        </div>
      </div>
      <div class="dashboard-info" v-if="filterProtocol.length">
        <div class="dashboard-info-label">
          <span>Protocole&nbsp;:</span>
        </div>
        <div class="dashboard-info-value">
          <span>{{ filterProtocol.map(f => f.value).join(', ')  }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="dashboard">
    <Widget :title="widgets.notation.title" name="notations" :double-height="!isUnion">
      <NotationsWidget @widget-emits-title="refreshTitle($event)" />
    </Widget>

    <Widget v-if="displayWidget('map')
    && helperService.userHasPermission(widgets.map.permissions)" :title="widgets.map.title" name="map" double-width
      noscroll @widget-emits-close="closeWidget($event)">
      <MapWidget v-model="trialsFilters" :isUnion="isUnion"/>
    </Widget>

    <Widget v-if="displayWidget('calendar')
    && helperService.userHasPermission(widgets.calendar.permissions)" :title="widgets.calendar.title" name="calendar"
      noscroll @widget-emits-close="closeWidget($event)">
      <CalendarWidget v-model="calendarFilters" />
    </Widget>

    <Widget v-if="displayWidget('protocols')
    && helperService.userHasPermission(widgets.protocols.permissions)" :title="widgets.protocols.title"
      name="protocols" double-width :linkURL="widgets.protocols.linkUrl" :linkText="widgets.protocols.linkText" color="primary"
      @widget-emits-close="closeWidget($event)">
      <ProtocolsWidget v-model="protocolFilters" name="protocols" @widget-emits-title="refreshTitle($event)" @widget-emits-link="refreshLink($event)" :isUnion="isUnion"/>
    </Widget>

    <Widget v-if="displayWidget('trials')
    && helperService.userHasPermission(widgets.trials.permissions)" :title="widgets.trials.title" name="trials"
      linkURL="essais" linkText="Voir tous les essais en cours" @widget-emits-close="closeWidget($event)" :double-width="isUnion">
      <TrialsWidget v-model="trialsFilters" @widget-emits-title="refreshTitle($event)" :isUnion="isUnion"/>
    </Widget>

    <Widget v-if="displayWidget('users')
    && helperService.userHasPermission(widgets.users.permissions, 'OR')" :title="widgets.users.title" name="users"
      linkURL="users" linkText="Voir tous les utilisateurs" @widget-emits-close="closeWidget($event)">
      <UsersWidget v-model="usersFilters" @widget-emits-title="refreshTitle($event)" />
    </Widget>

    <Widget v-if="displayWidget('protocolsShared')
    && helperService.userHasPermission(widgets.protocolsShared.permissions)" :title="widgets.protocolsShared.title"
      name="protocols-shared" double-width linkURL="protocolsShare"
      linkText="Voir tous les protocoles proposés au partage" @widget-emits-close="closeWidget($event)">
      <ProtocolsWidget v-model="sharedFilters" name="protocolsShared" @widget-emits-title="refreshTitle($event)" />
    </Widget>

    <Widget v-if="displayWidget('summary')" :title="widgets.summary.title" name="summary" noscroll
      @widget-emits-close="closeWidget($event)">
      <SummaryWidget v-model="years" name="summary" />
    </Widget>
  </div>
</template>

<script>
import SelectExtended from '@/components/form/SelectExtended.vue'
import Widget from '@/components/widget/Widget.vue'
import MapWidget from '@/components/widget/MapWidget.vue'
import NotationsWidget from '@/components/widget/NotationsWidget.vue'
import CalendarWidget from '@/components/widget/CalendarWidget.vue'
import UsersWidget from '@/components/widget/UsersWidget.vue'
import TrialsWidget from '@/components/widget/TrialsWidget.vue'
import ProtocolsWidget from '@/components/widget/ProtocolsWidget.vue'
import SummaryWidget from '@/components/widget/SummaryWidget.vue'
import MiniTag from '@/components/base/MiniTag.vue'

export default {
  name: 'DashboardView',
  components: {
    SelectExtended,
    Widget,
    MapWidget,
    NotationsWidget,
    UsersWidget,
    CalendarWidget,
    TrialsWidget,
    ProtocolsWidget,
    SummaryWidget,
    MiniTag,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      widgets: {
        notation: {
          title: 'Notations en cours',
          excludeInWidgetSelection: true,
        },
        protocols: {
          title: 'Mes protocole(s)',
          permissions: 'protocole_list',
          linkText: 'Voir tous les protocoles',
          linkUrl: 'protocols',
          includeInWidgetSelection: true,
        },
        protocolsShared: {
          title: 'Protocole(s) proposés au partage',
          permissions: 'protocole_shared',
          includeInWidgetSelection: true,
        },
        trials: {
          title: 'Essai(s) en cours',
          permissions: 'essai_list',
          includeInWidgetSelection: true,
        },
        users: {
          title: 'Utilisateur(s)',
          permissions: 'utilisateur_list_full,utilisateur_list_entite',
          includeInWidgetSelection: true,
        },
        calendar: {
          title: 'Calendrier des tâches',
          permissions: 'essai_list,essaievenement_olist',
          includeInWidgetSelection: true,
        },
        map: {
          title: 'Localisation des essais',
          permissions: 'essai_list',
          includeInWidgetSelection: true,
        },
        summary: {
          title: 'Résumé chiffré',
          permissions: 'essai_list',
          includeInWidgetSelection: true,
        },
      },
      displayWidgets: [],
      menuWidgets: [],
      filterThematique: [],
      filterYearByAgriculturalSeason: [],
      filterCulture: [],
      filterEntite: [],
      filterProtocol: [],
      yearsData: [],
      filters: '',
      years: '',
      trialsFilters: '',
      calendarFilters: [],
      usersFilters: '',
      sharedFilters: '',
      protocolFilters: '',
      isOnline: navigator.onLine,
      entity_id: null,
    }
  },

  watch: {
    displayWidgets() {
      this.setLocalStorage('displayWidgets')
    },
  },
  computed: {
    isUnion() {
      return ['CENTRALEACHATNATIONALE', 'CENTRALEACHAT', 'UNIONCOOPERATIVE'].includes(this.$store.state.auth.user.data.entite.type.uid)
    },
  },
  beforeUpdate() {
    this.isOnline = navigator.onLine
  },
  mounted() {
    if (this.isOnline) {
      this.emitter.emit('open-loader')
      this.menuWidgets = this.widgetMenuItems()
      this.displayWidgets = this.getFromLocalStorage('displayWidgets')
      this.initDashboard()
      this.entity_id = this.$store.state.auth.user.data.entite.id
    }
  },

  methods: {
    async initDashboard() {
      this.yearsData = this.$store.state.app.annee
      const filterByAgriculturalSeason = this.protocolService.filterByAgriculturalSeason('id', true)
      if (Object.keys(filterByAgriculturalSeason.years).length > 0) {
        this.filterYearByAgriculturalSeason = filterByAgriculturalSeason.years
      }
      this.emitParams()
      this.emitter.emit('close-loader')
    },
    // Must we display the widget?
    displayWidget(widget) {
      if (!this.isOnline) {
        return false
      }
      return this.displayWidgets.filter((w) => w.key === widget)?.length > 0
    },

    closeWidget(event) {
      let widget = event
      if (widget === 'protocols-shared') {
        widget = 'protocolsShared'
      }
      this.displayWidgets = this.displayWidgets.filter((w) => w.key !== widget)
    },

    refreshTitle(event) {
      this.widgets[event.widget].title = event.title
    },

    refreshLink(event) {
      this.widgets[event.widget].linkUrl = event.url
      this.widgets[event.widget].linkText = event.text
    },

    emitParams() {
      const params = {
        annee: this.filterYearByAgriculturalSeason.length > 0
          ? this.filterYearByAgriculturalSeason.map((year) => year.key).join(',')
          : null,
        culture: this.filterCulture.length
          ? this.filterCulture.map((culture) => culture.key).join(',')
          : null,
        thematique: this.filterThematique.length
          ? this.filterThematique.map((thematique) => thematique.key).join(',')
          : null,
        entite: this.filterEntite.length
          ? this.filterEntite.map((entite) => entite.key).join(',')
          : null,
        protocol: this.filterProtocol.length
          ? this.filterProtocol.map((protocol) => protocol.key).join(',')
          : null,
      }
      this.filters = this.buildFilters(params)
      this.years = this.filterYearByAgriculturalSeason.length > 0 ? this.filterYearByAgriculturalSeason.map((year) => year.value).join(',') : null
      this.protocolFilters = this.buildFilters(params, '', 'protocol')
      this.trialsFilters = this.buildFilters(params, 'protocole.')
      this.calendarFilters = this.buildFilters(params, '', 'calendar')
      this.usersFilters = this.buildFilters(params, '', 'user')
      this.sharedFilters = this.buildFilters(params, '', 'shared')
      console.log('this.usersFilters', this.usersFilters)
    },

    buildFilters(params, prefix = '', type) {
      const filters = {
        annee: params.annee
          ? `${prefix}annee.id:in(${params.annee})`
          : null,
        culture: params.culture
          ? `${prefix}culture.id:in(${params.culture})`
          : null,
        thematique: params.thematique
          ? `${prefix}thematique.id:in(${params.thematique})`
          : null,
        entite: params.entite
          ? `${prefix}entite.id:in(${params.entite})`
          : null,
        protocol: params.protocol
          ? `${prefix}id:in(${params.protocol})`
          : null,
      }

      // Ne pas trier par saison agriculture
      // Date par défaut : l'année courante
      if (type === 'calendar') {
        // eslint-disable-next-line
        params.annee = this.getCurrentYear().map((year) => year.key).pop()
        return params
      }

      if (type === 'shared') {
        if (params.annee && params.annee.length) {
          const toAdd = []
          const annees = params.annee.split(',')
          annees.forEach((a) => {
            const anneeFilter = `[[date_partage:gte(${a}-01-01)|n|date_partage:lte(${a}-12-31)]]`
            toAdd.push(anneeFilter)
          })

          filters.annee = `[${Object.values(toAdd).filter((value) => value).join('|u|')}]`
        }
      }

      if (type === 'protocol') {
        if (this.filterYearByAgriculturalSeason.length > 0) {
          filters.annee = `annee.id:in(${this.filterYearByAgriculturalSeason.map((year) => year.key).join(',')})`
        } else {
          filters.annee = ''
        }
        filters.entite = ''
      }

      if (type === 'user') {
        filters.annee = this.filterYearByAgriculturalSeason.length > 0 ? `${prefix}annee:${this.filterYearByAgriculturalSeason.map((year) => year.value).join(',')}` : null
        filters.culture = params.culture ? `${prefix}culture:${params.culture}` : null
        filters.thematique = params.thematique ? `${prefix}thematique:${params.thematique}` : null
        return Object.values(filters).filter((value) => value).join('|')
      }

      return Object.values(filters).filter((value) => value).join('|n|')
    },

    setLocalStorage(item) {
      if (!localStorage.getItem(item)) {
        localStorage.setItem(item, JSON.stringify(this[item]))
      } else {
        localStorage.removeItem(item)
        this.setLocalStorage(item)
      }
    },

    getFromLocalStorage(item) {
      if (localStorage.getItem(item)) {
        this[item] = JSON.parse(localStorage.getItem(item))
        return this[item]
      }

      return this.menuWidgets.map((menuWidget) => ({
        key: menuWidget.value,
        value: menuWidget.label,
      }))
    },

    widgetMenuItems() {
      const menu = []
      const cloneWidgets = JSON.parse(JSON.stringify(this.widgets))

      Object.values(cloneWidgets).forEach((widget, index) => {
        let toAdd = false
        if (widget.permissions && this.helperService.userHasPermission(widget.permissions, 'OR') && !widget.excludeInWidgetSelection) {
          if ((widget.in_union === true && this.isUnion)) {
            toAdd = true
          } else if ((widget.in_union === false && !this.isUnion)) {
            toAdd = true
          }

          if (widget.includeInWidgetSelection) {
            toAdd = true
          }

          if (toAdd) {
            menu.push({
              label: widget.title,
              value: Object.keys(cloneWidgets)[index],
            })
          }
        }
      })
      return menu
    },

    getCurrentYear() {
      return this.yearsData
        .filter((year) => year.valeur === new Date().getFullYear().toString())
        .map((year) => ({
          key: year.id,
          value: year.valeur,
        }))
    },
  },
}
</script>

<style lang="scss" scoped>
// DASHBOARD

$widget-base-height: 40.8rem;

.dashboard {
  display: grid;
  gap: $gutter-half;
  grid-auto-rows: $widget-base-height; // All rows have same, fixed height
  grid-auto-flow: dense; // CSS Grid magic!

  @include bp("md") {
    gap: $gutter;
  }

  @include bp("xs") {
    grid-template-columns: 1fr 1fr;
  }

  @include bp("lg") {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include bp("xl") {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include bp("xxl") {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

// DASHBOARD TOPBAR

.dashboard-topbar {
  @include bp('xs', true) {
    padding: $gutter-quarter;
    padding-bottom: 0;
  }

  @include bp('sm') {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: $gutter;
  }

  @include bp('xl') {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  padding-bottom: $gutter-half;
  border-bottom: 1px solid $color-gray-light;
  margin-bottom: $gutter;
}

// Dashboard filters

.dashboard-filters {
  @include bp('sm', true) {
    margin-bottom: $gutter-half;
  }

  grid-column: span 2;
  display: flex;
  gap: $gutter-quarter;
  row-gap: $gutter-half;
  flex-wrap: wrap;

  @include bp('xl') {
    grid-column: span 3;
  }
}

.dashboard-filter {
  flex-basis: 100%;
  max-width: 100%;

  @include bp('xxs') {
    flex-basis: calc(100% / 2 - #{$gutter-quarter});
    max-width: calc(100% / 2 - #{$gutter-quarter});
  }

  @include bp('xs') {
    flex-basis: calc(100% / 3 - #{$gutter-quarter});
    max-width: calc(100% / 3 - #{$gutter-quarter});
  }

  @include bp('xl') {
    flex-basis: calc(100% / 5 - #{$gutter-quarter});
    max-width: calc(100% / 5 - #{$gutter-quarter});
  }
}

::v-deep(.multiselect__custom-label) {
  white-space: nowrap;
}

// Dashboard tools

.dashboard-tools {
  @include bp('xl') {
    grid-column: 4;
  }
}

.dashboard-infos {
  display: flex;
  gap: $gutter-half $gutter;
  flex-wrap: wrap;

  @include bp('sm') {
    grid-column: span 3;
  }

  @include bp('xl') {
    grid-column: span 4;
  }
}

.dashboard-info {
  display: flex;
  gap: $gutter-quarter;
}

.dashboard-info-label {
}

.dashboard-info-value {
  font-weight: $font-weight-black;
}

.dashboard-info-value--tags {
  display: flex;
  gap: $gutter-eighth;
  align-items: center;
}
</style>
